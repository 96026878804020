import PropTypes from "prop-types";
import clsx from "classnames";
import { Col, Container, Row, Card } from "react-bootstrap";
import { Description, Image, Title, CTA } from "@/ui";
import classes from "./styles.module.scss";
import { useEffect, useState } from "react";
import { JobSearchStandaloneWidget } from "@sourceflow-uk/job-search";
import "@sourceflow-uk/job-search/dist/bundle.css";
import SearchIcon from "@/assets/search.svg";

export default function Hero({
    className,
    card1_title,
    card1_subtitle,
    card1_cta,
    card1_bg,
    card1_img,
    card2_title,
    card2_subtitle,
    card2_cta,
    card2_bg,
    card2_img
  }) {

  return (
    <div className={clsx(className, classes.hero)}>
      <Container>
        <Row>
          <Col xs={12} lg={6} className="mb-3 mb-lg-0">
            <Card className={clsx(classes.card, classes.card_one, "bg-primary text-white")}>
              <figure className={clsx(classes.bg_img)}>
                <Image img={card1_bg} size="960x800" alt="CTA Background 1" />
              </figure>
              <figure className={clsx(classes.img)}>
                <Image img={card1_img} size="960x800" alt="CTA Image 1" />
              </figure>
              <Card.Header>
                <Title tag="h1" title={card1_title} />
                <Description description={card1_subtitle} tag="h2" />
              </Card.Header>
              <Card.Body className="d-flex flex-column justify-content-end">
                <CTA label={card1_cta.label} href={card1_cta.href} variant={card1_cta.variant} className="mb-3" />
                <JobSearchStandaloneWidget
                    searchInput={{
                      queryInput: true,
                      locationInput: false,
                      radiusInput: false,
                    }}
                    submitDestination='/jobs/'
                    translations={{
                      "input.placeHolder": "Search by job title or location...",
                      "button.text": (
                          <SearchIcon />
                      ),
                    }}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} lg={6} className="mb-3 mb-md-0">
            <Card className={clsx(classes.card, classes.card_two, "bg-tertiary text-white")}>
              <figure className={clsx(classes.bg_img)}>
                <Image img={card2_bg} size="960x800" alt="CTA Background 2" />
              </figure>
              <figure className={clsx(classes.img)}>
                <Image img={card2_img} size="960x800" alt="CTA Image 2" />
              </figure>
              <Card.Header>
                <Title tag="h2" title={card2_title} />
                <Description description={card2_subtitle} tag="h3" />
              </Card.Header>
              <Card.Body className="d-flex flex-column justify-content-end">
                <CTA label={card2_cta.label} href={card2_cta.href} variant={card2_cta.variant} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      


      {/* <figure className={classes.hero__img}>
        <Row>
          <Col xs={12} md={6} className={clsx("px-0", { focus: focus === 1 })}>
            <Image img={img1} size="960x800" alt="" />
          </Col>
          <Col xs={12} md={6} className={clsx("px-0", { focus: focus === 2 })}>
            <Image img={img2} size="960x800" alt="" />
          </Col>
        </Row>
      </figure>
      <div className={classes.hero__content}>
        <Container>
          <Title tag="h1" title={"TEST"} />
          <Description description={subtitle} tag="h2" />
        </Container>
      </div> */}



    </div>
  );
}

Hero.defaultProps = {
  className: "",
};

Hero.propTypes = {
  className: PropTypes.string,
};
